<template>
  <div class="content-wrapper">
    <!-- Main content -->
    <section class="content">
      <div class="table-content">
        <div class="table-header">
          <a-button type="primary" @click="addAccountTable">{{ $t('新增科目表') }}</a-button>
        </div>
        <div class="table-body flex">
          <a-card
            class="mr10 mb10 table-item"
            size="small"
            style="width: 300px"
            v-for="(item, index) in tableList"
            :key="index"
          >
            <div slot="title" class="fz16">{{ item.name }}</div>
            <div class="line-block">
              <span class="label-text">{{ $t('编码') }}：</span>
              <span class="text-link" @click="viewDetail(item)">{{ item.code }}</span>
            </div>
            <div class="line-block">
              <span class="label-text">{{ $t('科目级次') }}：</span>
              <span>{{ item.structure }}</span>
            </div>
            <div class="line-block">
              <span class="label-text">{{ $t('创建组织') }}：</span>
              <span>{{ item.business_unit_name }}</span>
            </div>
            <div class="line-block">
              <span class="label-text">{{ $t('系统预置') }}：</span>
              <span>{{ item.is_preset ? $t('是') : $t('否') }}</span>
            </div>
          </a-card>
          <a-empty v-if="isEmpty" />
        </div>
      </div>
    </section>

    <a-modal
      :title="$t('科目表')"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirmLoading="confirmLoading"
      :width="800"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t('编码')" prop="code">
              <a-input v-model="form.code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('名称')" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t('会计要素表')" prop="root_element_code">
              <SelectAccountElement :disabled="!!form.id" :code.sync="form.root_element_code" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('创建组织')" prop="business_unit_code">
              <CommonSelect
                :isBu="true"
                :list="$store.state.settlementBuList"
                :placeholder="$t('创建组织')"
                :disabled="!!form.id"
                :code.sync="form.business_unit_code"
                :name.sync="form.business_unit_name"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t('分隔符')" prop="delimiter">
              <a-select :disabled="!!form.id" v-model="form.delimiter">
                <a-select-option value=".">.</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="structure">
              <template slot="label">
                <span>{{ $t('科目级次') }}</span>
                <a-tooltip>
                  <template slot="title">
                    {{
                      $t('如将科目级次设置为4-2-2-2，其中4表示对应级次编码长度为4，其它以此类推。')
                    }}
                  </template>
                  <a-icon type="question-circle" class="ml2" />
                </a-tooltip>
              </template>
              <a-input v-model="form.structure" @blur="handleBlurStructure" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('备注')">
              <a-input v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountElement from '@/component/selectAccountElement'
import deepClone from '@/common/deepClone'
export default {
  name: 'accountTable',
  components: { SelectAccountElement },
  mounted() {
    this.getList()
  },
  data() {
    return {
      loading: false,
      tableList: [],
      isEmpty: false,
      visible: false,
      confirmLoading: false,
      form: {
        delimiter: '.',
      },
      rules: {
        code: [{ required: true, message: this.$t('请输入编码'), trigger: 'blur' }],
        name: [{ required: true, message: this.$t('请输入名称'), trigger: 'blur' }],
        structure: [
          { required: true, message: this.$t('请输入科目级次'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              let err = this.judgeStructure(value)
              if (err) {
                callback(new Error(err))
              } else {
                callback()
              }
            },
          },
        ],
        root_element_code: [
          { required: true, message: this.$t('请选择会计要素表'), trigger: 'change' },
        ],
        business_unit_code: [
          { required: true, message: this.$t('请选择创建组织'), trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    getList() {
      this.isEmpty = false
      this.loading = true
      http({
        url: api.getAccountTableList,
        type: 'post',
        success: (res) => {
          this.loading = false
          this.tableList = res.result.list || []
          if (this.tableList.length === 0) {
            this.isEmpty = true
          }
        },
        error: (err) => {
          this.loading = false
          this.$message.error(err.msg)
        },
      })
    },
    addAccountTable() {
      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      http({
        url: api.createOrUpdateAccountTable,
        data: {
          ...this.form,
        },
        success: (res) => {
          if (res.success) {
            this.$message.success(this.$t('新增成功'))
            this.getList()
            this.form = {}
          }
          this.confirmLoading = false
          this.visible = false
        },
        fail: () => {
          this.confirmLoading = false
        },
      })
    },
    handleCancel() {
      this.visible = false
    },
    //校验structure
    judgeStructure(value) {
      let err = ''
      if (value === '' || value === undefined) {
        err = this.$t('请输入科目级次')
      } else if (value.includes('-')) {
        const arr = value.split('-')
        //只能是数字，每一项超过10也不行
        if (arr.some((item) => isNaN(item) || item > 10)) {
          err = this.$t('科目级次只能是数字，且每一项不超过10')
        }
      } else if (isNaN(value)) {
        err = this.$t('科目级次只能是数字')
      }
      if (err) {
        return err
      } else {
        return ''
      }
    },
    handleBlurStructure() {
      let err = this.judgeStructure(this.form.structure)
      if (err) {
        return
      }
      this.form.structure = this.splitOrKeep(this.form.structure)
    },
    splitOrKeep(input) {
      // 检查输入是否已经是分割好的格式
      if (/^\d+(-\d+)+$/.test(input)) {
        return input
      }

      // 否则，将输入分割成单个数字并用"-"连接
      return input.split('').join('-')
    },
    viewDetail(item) {
      this.form = {
        ...deepClone(item),
      }
      this.visible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.line-block {
  font-size: 14px;
  color: rgb(153, 153, 153);
  margin-top: 5px;
}
.table-body {
  flex-wrap: wrap;
}
</style>
